<template>

  <div id="login">

    <div class="center">
      <router-link :to="{ name : 'home', params : { lang : currentLanguageCode }}" class="retina-logo" >
        <img src="@public/img/vigneron-online/logo.gif" alt="Vigneron-Online">
      </router-link>
    </div>

    <div class="card login">
      <div class="card-body" style="padding: 40px;">
        <h3>Login to your Account</h3>

        <div class="style-msg2 errormsg" v-if="messages && messages.length > 0">
          <div class="msgtitle">Some errors occured:</div>
          <div class="sb-msg">
            <ul>
              <li v-for="(message, index) in messages" :key="index">
                {{ message.formattedMessage }}
              </li>
            </ul>
          </div>
        </div>

        <form v-if="isLoginFromUsernameAllowed" id="login-form" name="login-form" class="mb-0" action="#" method="post">

          <div class="col_full">
            <label for="login-form-username">Username:</label>
            <input type="text" id="login-form-username" name="login-form-username" value="" class="form-control not-dark" />
          </div>

          <div class="col_full">
            <label for="login-form-password">Password:</label>
            <input type="password" id="login-form-password" name="login-form-password" value="" class="form-control not-dark" />
          </div>

          <div class="col_full mb-0">
            <button class="button button-3d button-black m-0" id="login-form-submit" name="login-form-submit" value="login">Login</button>
            <a href="#" class="fright">Forgot Password?</a>
          </div>
        </form>

        <div v-if="isLoginFromUsernameAllowed" class="line line-sm"></div>

        <div class="center">
          <h4 v-if="isLoginFromUsernameAllowed" style="margin-bottom: 15px;">or Login with:</h4>
          <a href="/api/auth/facebook/login" class="button button-rounded si-facebook si-colored" >Facebook</a>
          <a href="/api/auth/google/login" class="button button-rounded si-google si-colored" >Google</a>
        </div>
      </div>
    </div>

    <div class="center copyright"><small>{{ $t("footer.copyright", [year]) }}</small></div>

  </div>

</template>

<style>
#login {
  height: 100%;
  width: 100%;
  position:absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login.card {
  background: #EEE;
  box-shadow: 0px 0px 10px 5px #DDD;
}

.login.card .errormsg {
  border-radius: .25rem;
  box-shadow: 0px 0px 10px 1px #e42c3e;
}

.login.card .center .button {
  display:block;
  margin: 5px 0px;
}

.center .retina-logo img {
  height:90px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.copyright {
  margin-top:10px;
}
</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { languagesTypes, messagesTypes } from '@fwk-client/store/types';

@Component({
  components: {},
  computed: {
      ...mapGetters({
          currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE,
          messages : 'messages/' + messagesTypes.getters.GET_MESSAGES
        })
  }
})
export default class Login extends mixins(GenericPage) {
  isLoginFromUsernameAllowed = false;

  year = (new Date()).getFullYear();
}
</script>